// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.expand-row__button[data-v-c8fd7f1a],.font-text[data-v-c8fd7f1a]{font-family:\"Open Sans\"}.font-header[data-v-c8fd7f1a]{font-family:\"Hero\"}.expand-row[data-v-c8fd7f1a]{border:0;box-sizing:border-box;border-bottom:1px solid rgba(241,214,245,.7)}.expand-row[data-v-c8fd7f1a]:last-child{border:0}.expand-row:last-child .expand-row__content[data-v-c8fd7f1a]{padding-bottom:0}.expand-row__button[data-v-c8fd7f1a]{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;cursor:pointer;font-weight:700;font-size:14px;line-height:20px;box-sizing:border-box}@media screen and (max-width:600px){.expand-row__button[data-v-c8fd7f1a]{padding-top:12px}}@media screen and (min-width:600px){.expand-row__button[data-v-c8fd7f1a]{padding-top:12px;padding-top:15px}}@media screen and (max-width:600px){.expand-row__button[data-v-c8fd7f1a]{padding-bottom:12px}}@media screen and (min-width:600px){.expand-row__button[data-v-c8fd7f1a]{padding-bottom:12px;padding-bottom:15px}}.expand-row__button i[data-v-c8fd7f1a]{transition:.3s ease-in-out}.expand-row__button.expand[data-v-c8fd7f1a]{color:#9529a3}.expand-row__button.expand i[data-v-c8fd7f1a]{transform:rotate(180deg)}.expand-row__content[data-v-c8fd7f1a]{background:#fff;overflow:hidden;transition:height .5s ease;box-sizing:border-box}.expand-row__content[data-v-c8fd7f1a] p{font-size:14px;line-height:22px}.expand-row__content.hidden[data-v-c8fd7f1a]{height:0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
