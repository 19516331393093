import { render, staticRenderFns } from "./Expand.vue?vue&type=template&id=c8fd7f1a&scoped=true&"
import script from "./Expand.vue?vue&type=script&lang=js&"
export * from "./Expand.vue?vue&type=script&lang=js&"
import style0 from "./Expand.vue?vue&type=style&index=0&id=c8fd7f1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8fd7f1a",
  null
  
)

export default component.exports